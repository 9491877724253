import { Avatar as AntAvatar, AvatarProps as AntAvatarProps } from 'antd';
import { Fallback } from './fallback';

interface AvatarProps extends AntAvatarProps {
  src?: string;
  customText?: string;
  username?: string;
  superSize?: boolean;
  containerClassName?: string;
}

export const Avatar = (props: AvatarProps) => {
  const { src, superSize, customText, username, containerClassName, ...rest } = props;

  const initials = username ? getInitials(username) : customText?.slice(0, 2);
  const style = superSize ? { width: '64px', height: '64px', fontSize: '20px' } : {};
  const isSrcValid = isValidURL(src);

  return (
    <div className={containerClassName}>
      {isSrcValid ? (
        <AntAvatar src={src} style={style} {...rest} />
      ) : initials ? (
        <AntAvatar style={style} {...rest}>
          <span className="font-bold">{initials.toUpperCase()}</span>
        </AntAvatar>
      ) : (
        <AntAvatar src={<Fallback />} style={style} {...rest} />
      )}
    </div>
  );
};

function getInitials(username: string) {
  if (!username) {
    return null;
  }
  const nameParts = username.trim().split(' ');

  if (nameParts.length < 2) {
    return null; // Return null if there is no last name
  }

  const firstInitial = nameParts[0].charAt(0).toUpperCase();
  const lastInitial = nameParts[nameParts.length - 1].charAt(0).toUpperCase();

  return firstInitial + lastInitial;
}

const isValidURL = (src: string | undefined) => {
  if (!src) {
    return false;
  }

  try {
    new URL(src);
    return true;
  } catch {
    return false;
  }
};
